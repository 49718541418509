<template>
  <div class="learning-path-application">
    <div class="content-area-wrapper mb-5">
      <portal-target
          name="content-renderer-sidebar-left"
          slim
        />

      <div class="content-right">
        <div class="content-wrapper">
          <div class="content-body">
            <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
            <div style="height: inherit">
              <div
                class="body-content-overlay"
                :class="{'show': currentBreakPoint == 'lg' || currentBreakPoint == 'xl'? false : mqShallShowLeftSidebar}"
                @click="mqShallShowLeftSidebar = false"
              />

              <!-- Main List -->
              <div class="email-app-list">
                
                <!-- App Action Bar -->
                <div class="app-action">
                  <div class="action-left pt-25">
                    <b>{{$t('learning_path.searcher_'+lp_source)}}</b>
                  </div>
                  <div class="action-right">
                    <div v-if="isAllocation" class="text-center">
                      <b-button @click="unselectAllLPs" size="sm" variant="flat-danger">{{ $t('learning_path.delete_all_allocation')}}</b-button>
                    </div>
                    <!-- <b-button v-if="isAllocation" variant="success" size="sm"
                      v-b-tooltip.hover.top="$t('forms.save_changes')"
                      @click="saveAllocation">
                      <span class="d-none d-lg-block">{{$t('forms.save_changes')}}</span>
                      <feather-icon
                        class="d-block d-lg-none"
                        icon="SaveIcon"
                        size="18"
                      />
                    </b-button> -->
                    <b-button-group class="mr-1" v-if="isReport">
                      <b-button variant="outline-info" size="sm"
                        v-b-tooltip.hover.top="$t('learning_path.select_all')"
                        @click="SelectAllLP">
                        <span class="d-none d-lg-block">{{$t('learning_path.select_all')}}</span>
                        <feather-icon
                          class="d-block d-lg-none"
                          icon="CheckSquareIcon"
                          size="18"
                        />
                      </b-button>
                      <b-button  variant="outline-warning" size="sm"
                        v-b-tooltip.hover.top="$t('learning_path.unselect_all')"
                        @click="UnselectAllLP">
                        <span class="d-none d-lg-block">{{$t('learning_path.unselect_all')}}</span>
                        <feather-icon
                          class="d-block d-lg-none"
                          icon="XSquareIcon"
                          size="18"
                        />
                      </b-button>
                    </b-button-group>
                  </div>
             
                  <!-- Floating Button and Modal -->
                  <template v-if="isReport">
                    <div class="floating-button">
                      <b-button
                        size="lg"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        :variant="selected_lps.length>0? 'success' : 'light' "
                        class="btn btn-icon rounded-circle"
                        v-b-modal.modal-selected_lps
                        v-b-tooltip.hover.top="$t('Learning Paths')"
                      >
                        <feather-icon
                            icon="LayersIcon"
                            size="20"
                          />
                      </b-button>
                    </div>
                    <b-modal
                      id="modal-selected_lps"
                      :title="$t('Learning Paths')"
                      ok-only
                      :ok-title="$t('forms.close')"
                    >
                      <b-card-text>
                        <selected-learning-path-list
                          :selected_lps="selected_lps_obj"
                          @toggle-lp-in-list="selectLP"
                        />
                      </b-card-text>
                    </b-modal>
                  </template>

                  <!-- Floating Right Margin Button and Sidebar -->
                  <template v-if="isAllocation">
                    <div class="floating-bottom-assign-summary-button">
                      <b-button
                        size="md"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="info"
                        class="btn btn-icon mr-2"
                        style="border-radius: 8px !important;"
                        v-b-toggle.sidebar-right
                      >
                          <svg width="18px" height="18px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path fill="#ffffff" d="M507.31 84.69L464 41.37c-6-6-14.14-9.37-22.63-9.37H288V16c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v16H56c-13.25 0-24 10.75-24 24v80c0 13.25 10.75 24 24 24h385.37c8.49 0 16.62-3.37 22.63-9.37l43.31-43.31c6.25-6.26 6.25-16.38 0-22.63zM224 496c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V384h-64v112zm232-272H288v-32h-64v32H70.63c-8.49 0-16.62 3.37-22.63 9.37L4.69 276.69c-6.25 6.25-6.25 16.38 0 22.63L48 342.63c6 6 14.14 9.37 22.63 9.37H456c13.25 0 24-10.75 24-24v-80c0-13.25-10.75-24-24-24z"/></svg>
                          <span class="ml-1">{{ $t('View learning path assignment')}}</span>
                      </b-button>

                      <b-button
                        size="md"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="success"
                        class="btn btn-icon"
                        style="border-radius: 8px !important; line-height: normal;"
                        @click="saveAllocation"
                      >
                        <feather-icon
                          icon="SaveIcon"
                          size="18"
                        />
                        <span class="ml-1">{{ $t('forms.save')}}</span>
                     </b-button>
                    </div>
                    <b-sidebar
                      id="sidebar-right"
                      bg-variant="white"
                      right
                      backdrop
                      shadow
                    >
                    <sidebar-allocation-content/>
                    </b-sidebar>
                  </template>
                
                </div>
                <!-- App Searchbar Header -->
                <div class="app-fixed-search d-flex align-items-center">

                  <!-- Toggler -->
                  <div class="sidebar-toggle d-block d-lg-none ml-1">
                    <feather-icon
                      icon="MenuIcon"
                      size="21"
                      class="cursor-pointer"
                      @click="mqShallShowLeftSidebar = true"
                    />
                  </div>

                  <!-- Searchbar -->
                  <div class="d-flex align-content-center justify-content-between w-100">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon
                          icon="SearchIcon"
                          class="text-muted"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        @keyup.enter="searchByTitle"
                        v-model="searchQuery"
                        :placeholder="$t('learning_path.searcher_placeholder')"
                      />
                      <b-input-group-append is-text @click="clearSearchByTitle" class="cursor-pointer">
                        <feather-icon
                          icon="XIcon"
                          class="text-muted"
                        />
                      </b-input-group-append>
                        <b-button variant="secondary" @click="searchByTitle" :disabled="searchQuery.length > 0 && searchQuery.length < 3">
                          {{$t('learning_path.search')}}
                        </b-button>
                    </b-input-group>
                  </div>
                </div>

                

                <!-- LPs List -->
                <vue-perfect-scrollbar
                  :settings="perfectScrollbarSettings"
                  class="email-user-list scroll-area"
                >
                  <ul class="email-media-list">
                    <b-media
                      v-for="lp in learning_paths"
                      :key="lp.id"
                      tag="li"
                      no-body
                      @click="isAllocation?showAllocationView(lp):isReport?selectLP(lp):showLPDetails(lp)"
                    >

                      <b-media-aside class="media-left mr-50">
                        <b-avatar
                          class="avatar no-bg"
                          size="40"
                          :src="require('@/assets/images/learning_paths/'+lp.img+'.png')"
                        />
                        <div class="user-action"></div>
                      </b-media-aside>

                      <b-media-body>
                        <div class="mail-details">
                          <div class="mail-items">
                            <h5 class="mb-25">
                              {{ lp.name }}
                            </h5>
                            <span>{{ truncate(lp.description)}}</span>
                            <p class="mt-50 mb-0" v-if="lp.source.length > 0">
                              <feather-icon
                                size="15"
                                class="mr-25 text-primary"
                                icon="TagIcon"
                              />
                              <b-badge v-for="s in lp.source" :key="s"
                                class="text-capitalize mr-50"
                                :variant="resolveSourceVariant(s)"
                              >
                                {{ $t('learning_path.'+s) }}
                              </b-badge>
                            </p>
                          </div>
                         <div style="width:200px; text-align:right;">
                            <template v-if="isReport">
                              <feather-icon
                                v-b-tooltip.hover.up="isItemChecked(lp.id) ? $t('learning_path.remove'):$t('learning_path.add')"
                                :icon="isItemChecked(lp.id) ? 'CheckSquareIcon' : 'SquareIcon'"
                                size="17"
                                :color="isItemChecked(lp.id)?'#87bced':''"
                                @click.stop="selectLP(lp)"
                              />
                              <feather-icon
                                v-b-tooltip.hover.up="$t('learning_path.information')"
                                icon="InfoIcon"
                                size="17"
                                class="ml-1"
                                @click.stop="showLPDetails(lp)"
                              />
                            </template>
                            <template v-else-if="isAllocation">
                              <feather-icon
                                v-b-tooltip.hover.up="$t('learning_path.information')"
                                icon="InfoIcon"
                                size="17"
                                class="ml-1"
                                @click.stop="showLPDetails(lp)"
                              />
                            </template>
                            <template v-else-if="isCatalog">
                              <feather-icon
                                v-b-tooltip.hover.up="$t('learning_path.information')"
                                icon="InfoIcon"
                                size="17"
                                class="ml-1"
                                @click.stop="showLPDetails(lp)"
                              />
                            </template>
                            <template v-else>
                              <!-- NO SE VA A USAR POR AHORA -->
                              <!-- <feather-icon
                                v-b-tooltip.hover.up="$t('learning_path.set_as_fav')"
                                icon="StarIcon"
                                size="17"
                                :class="{ 'text-warning fill-current': lp.isStarred }"
                                @click.stop="toggleStar(lp)"
                              /> -->
                              
                                <feather-icon
                                  v-b-tooltip.hover.up="$t('forms.edit')"
                                  v-if="lp.source.includes('own') || $store.getters['user/getUserData'].id == 1081"
                                  icon="EditIcon"
                                  size="17"
                                  @click.stop="editLP(lp)"
                                />
                                <feather-icon
                                  v-b-tooltip.hover.up="$t('learning_path.save_copy')"
                                  icon="CopyIcon"
                                  class="ml-1"
                                  size="17"
                                  @click.stop="copyLP(lp)"
                                />
                                <feather-icon
                                  v-if="lp.source.includes('own') || $store.getters['user/getUserData'].id == 1081"
                                  v-b-tooltip.hover.up="$t('learning_path.delete')"
                                  icon="TrashIcon"
                                  class="ml-1 text-danger"
                                  size="17"
                                  @click.stop="deleteLP(lp)"
                                />
                            </template>
                          </div>
                          
                        </div>

                        <!-- <div class="mail-message">
                          <p class="text-truncate mb-0">
                            {{ lp.description }}
                          </p>
                          
                        </div> -->
                      </b-media-body>
                    </b-media>
                  </ul>
                  <div
                    class="no-results"
                    :class="{'show': !learning_paths.length}"
                  >
                    <h5>{{ lp_source == 'smileandlearn'?$t('learning_path.select_hierarchy_section'):$t('table.empty') }}</h5>
                  </div>
                </vue-perfect-scrollbar>
              </div>

              <!-- LP View/Detail -->
              <learning-path-view
                :isReport="isReport"
                :class="{'show': showLearningPathDetails}"
                :lp="learningPathViewData"
                :isItemChecked="isItemChecked(learningPathViewData.id)"
                @close-email-view="showLearningPathDetails = false, learningPathViewData={}"
                @select-lp="selectLP"
                @delete-lp="deleteLP"
                @edit-lp="editLP"
                @copy-lp="copyLP"
                @toggle-star="toggleStar"
                @allocate-lp="(lp) => {showAllocationView(lp); showLearningPathDetails = false}"
                :isAllocation="isAllocation"
              />

              <!-- LP View/Detail -->
              <learning-path-allocation-view
                :loading="loading"
                :isAllocation="isAllocation"
                :isReport="isReport"
                :class="{'show': showLearningPathAllocationView}"
                :lp="learningPathAllocationData"
                :isItemChecked="isItemChecked(learningPathAllocationData.id)"
                @close-email-view="showLearningPathAllocationView = false, learningPathAllocationData={}"
                @select-lp="selectLP"
                @delete-lp="deleteLP"
                @edit-lp="editLP"
                @copy-lp="copyLP"
                @toggle-star="toggleStar"
                @save-allocation="saveAllocation"
                @showLPDetails="(lp)=>{
                  showLearningPathAllocationView = false;
                  showLPDetails(lp)}"
              />

              <!-- Sidebar -->
              <portal to="content-renderer-sidebar-left">
                <left-sidebar
                  :isCatalog="isCatalog"
                  :isAllocation="isAllocation"
                  :isReport="isReport"
                  :learning_paths="$store.getters['learningPaths/getLearningPaths']"
                  :class="{'show': mqShallShowLeftSidebar}"
                  @close-left-sidebar="mqShallShowLeftSidebar = false"
                  @filter-lps="filterLearningPaths"
                  @selected-node="selectedNode"
                />
              </portal>
            </div>

          </div>
        </div>
      </div>
    </div>
      
  </div>
</template>

<script>
import {
  BBadge, BSidebar, VBToggle,BSpinner,
  BTooltip,
  BCardText, BModal, BButton, BButtonGroup, BFormInput, BInputGroup, 
  BInputGroupAppend, BInputGroupPrepend, BDropdown, BDropdownItem,
  BFormCheckbox, BMedia, BMediaAside, BMediaBody, BAvatar,VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { assignLearningPath, deleteLearningPath } from "@/api/learningpaths";
import LeftSidebar from './components/LeftSidebar.vue'
import LearningPathAllocationView from './components/LearningPathAllocationView.vue'
import LearningPathView from './components/LearningPathView.vue'
import SelectedLearningPathList from './components/SelectedLearningPathList.vue'
import SidebarAllocationContent from './components/SidebarAllocationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    SidebarAllocationContent,
    SelectedLearningPathList,
    BFormInput,
    BInputGroup,
    VBTooltip, 
    BTooltip,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BSpinner,
    BBadge,
    BCardText, BModal,
    BButton, 
    BButtonGroup,
    BSidebar, VBToggle,
    
    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    LeftSidebar,
    LearningPathView,
    LearningPathAllocationView
  },
   directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props:{
    isReport:{
      type: Boolean,
      required: false,
      default: false
    },
    isCatalog:{
      type: Boolean,
      required: false,
      default: false
    },
    isAllocation:{
      type: Boolean,
      required: false,
      default: false
    },
    selected_lps:{
      type: Array,
      required: false,
      default() {
          return []
      }
    }
  },
  created(){
    if (this.$store.getters['app/currentBreakPoint'] == 'lg'){
      this.mqShallShowLeftSidebar = true
    } else {
      this.mqShallShowLeftSidebar = false
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      // UI
      perfectScrollbarSettings,
    }
  },
  data(){
    return {
      loading: false,

      mqShallShowLeftSidebar: true,
      learning_paths: [],
      showLearningPathDetails: false,
      showLearningPathAllocationView: false,

      hierarchy_selected:'',
      searchQuery:'',
      learningPathViewData:{},
      learningPathAllocationData:{},

      loadingSearch:false,

      selected_lps_obj:[],
      lp_source: this.isReport && this.$store.getters['user/getUserData'].id == 1081 
        ? 'all'
        : this.$store.getters['user/getUserData'].id == 1081 
          ? 'smileandlearn' : 
            this.isReport?'all':'own',
    }
  },
  mounted(){
    if(this.$store.getters['user/getUserData'].id == 1081 || this.isCatalog){
      this.learning_paths= []
      this.lp_source = 'smileandlearn'
    } else {
      if(this.isReport){
        this.learning_paths= this.$store.getters['learningPaths/getLearningPaths']
      } else {
        this.learning_paths= this.$store.getters['learningPaths/getLearningPaths'].filter(l => l.source.includes('own'));
      }
    }
  },
  computed: {
    currentBreakPoint(){
      return this.$store.getters['app/currentBreakPoint']
    }
  },
  watch:{
    'currentBreakPoint': function(val, oldVal){
      if (val == 'lg'){
        this.mqShallShowLeftSidebar = true
      } else {
        this.mqShallShowLeftSidebar = false
      }
    },
    hierarchy_selected(){
      if(this.hierarchy_selected){
        let filteredData = this.$store.getters['learningPaths/getLearningPaths'].filter(l => l.hierarchy.includes(this.hierarchy_selected))
        // console.log(filteredData, "filtered data", this.hierarchy_selected)
        this.learning_paths = filteredData
      }
    }
  },
  methods:{
     truncate(str){
      if(str){
        return (str.length > 220) ? str.slice(0, 220-1) + '...' : str;
      }
    },
    unselectAllLPs(){
      this.$bvModal
        .msgBoxConfirm(this.$t('learning_path.confirm_unselect_all'), {
          title: this.$t('learning_path.delete_all_allocation'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.$store.commit('learningPaths/unselectAllAllocation')
            this.makeToast('warning', this.$t('Warning'), this.$t('learning_path.confirm_unselect_all_success_text'));
          }
        })
    },
    deleteLP(lp){
      // console.log("delete lp", lp)
      this.$bvModal
        .msgBoxConfirm(this.$t('learning_path.confirm_delete'), {
          title: this.$t('forms.delete')+' '+lp.name,
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'user_id': this.$store.getters['user/getUserData'].id,
              'lp_id': lp.id,
              'token': this.$store.getters['user/getUserData'].sso,
            }
            this.$http.post(deleteLearningPath, data).then( response =>{
              // console.log(response, "delete:")
              if(response.data.status == 200){
                this.$emit('refetch-data')
                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));
              } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
              this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            })
          }
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
        this.$bvToast.toast(msg, {
          title: tit,
          variant,
          solid: false,
        })
    },
    editLP(lp){
      // console.log("edit lp", lp)
      this.$router.push({ name: 'learning-paths-edit', params: {id: lp.id} })
    },
    copyLP(lp){
      // console.log("copy lp", lp)
      this.$router.push({ name: 'learning-paths-duplicate', params: {id: lp.id} })
    },
    clearSearchByTitle(){
      this.searchQuery = ''
      let filtered = []
      if(this.lp_source != 'all'){
        if(this.lp_source == 'smileandlearn'){
          filtered = []
        } else {
          filtered = this.$store.getters['learningPaths/getLearningPaths'].filter(l => l.source.includes(this.lp_source))
        }
      } else {
        filtered = this.$store.getters['learningPaths/getLearningPaths']
      }
      this.learning_paths = filtered
      this.hierarchy_selected = ''
      this.loadingSearch = false
    },
    searchByTitle(){
      // console.log(this.searchQuery, this.lp_source)
      this.loadingSearch = true
      let filtered = []
      if(this.searchQuery != ''){ 
        if(this.searchQuery.length >= 3){
          /* prefiltro por origen de las LP */
          let prefilter = []
          if(this.lp_source != 'all'){
            prefilter = this.$store.getters['learningPaths/getLearningPaths'].filter(l => l.source.includes(this.lp_source))
          } else {
            prefilter = this.$store.getters['learningPaths/getLearningPaths']
          }
          
          filtered = prefilter.filter(l => String(l.name).toLowerCase().includes(this.searchQuery.toLowerCase()))
          /* filtered = prefilter.filter(l => {
            if(typeof(l.name) != 'string'){
              // console.log(l.name, l.id)
            }
            //return l.name//.toLowerCase().includes(this.searchQuery.toLowerCase())
          }) */
          
          this.learning_paths = filtered
        } else {
          this.$bvTooltip.show('searchButton');
        }
        this.loadingSearch = false
      } else {
        this.clearSearchByTitle()
        //filtered = this.$store.getters['learningPaths/getLearningPaths'].filter(l => l.source == this.lp_source)
        //this.learning_paths = filtered
      }
    },
    async saveAllocation(){
      this.loading = true
      // console.log("saveAllocation")
      let dreamers_ids = []
      this.$store.getters['learningPaths/getDreamersByGroup'].map(group => {
        let d = group.linked.map(d => parseInt(d.id))
        d.filter(d_id => dreamers_ids.includes(d_id) ?null: dreamers_ids.push(d_id));
      })

      let data = {
        user_id:  this.$store.getters['user/getUserData'].id,
        allocation: this.$store.getters['learningPaths/getAllocation'],
        dreamers: dreamers_ids,
        token: this.$store.getters['user/getUserData'].sso,
      }
      // console.log("Data Allocation Save", data)

      await this.$http.post(assignLearningPath, data).then( response => {
          // console.log("assignLearningPath", response)
          if(response.data.status == 200){
            this.$store.commit('learningPaths/resetAllocationChanges')
            this.makeToast('success', this.$t('Success'),this.$t('learning_path.save_alloc_success'));
            this.loading = false
            //this.$router.push({name: 'learning-paths'})
          } else {
            // console.log("Err -> ", error);
            this.makeToast('danger', this.$t('Error'),this.$t(response.data.msg));
            this.loading = false  
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
          this.loading = false

        })

    },
    selectedNode(node){
      this.showLearningPathDetails = false
      this.showLearningPathAllocationView = false
      this.learningPathViewData={}
      this.learningPathAllocationData={}

      // console.log(node, "id node jerarquía seleccionada")
      this.hierarchy_selected = node
    },
    showAllocationView(lp){
      // console.log(lp, "allocation")
        

      this.learningPathAllocationData = lp
      this.showLearningPathAllocationView = true
    },
    showLPDetails(lp){
      // console.log(lp, 'show')
      let contents = []
      lp.contents.forEach(content_id => {
        let c = this.$store.getters['learningPaths/getContents'][content_id]
        if(c){
          c.id = content_id
          contents.push(c)
        }
      });
      lp.contents_obj = contents
      this.learningPathViewData = lp
      this.showLearningPathDetails = true
    },
    isItemChecked(lp_id){
      let sel_lps_ids = this.selected_lps.map(x => x.id)
      var index = sel_lps_ids.indexOf(lp_id);

      return index >= 0;
    },
    selectLP(lp){
      //// console.log(lp, 'select')
      //var index = this.selected_lps.indexOf(lp.id);
      let sel_lps_ids = this.selected_lps.map(x => x.id)
      var index = sel_lps_ids.indexOf(lp.id);

      let sel_lps = [...this.selected_lps]
      if (index !== -1) {
        sel_lps.splice(index, 1);
        this.selected_lps_obj.splice(index, 1);
      } else {
        sel_lps.push(lp)
        this.selected_lps_obj.push(lp)
      }  
      this.$emit('lps-selected', sel_lps)

    },
    SelectAllLP(){
      this.selected_lps_obj = [...this.$store.getters['learningPaths/getLearningPaths']]
      //let sel_lps = this.$store.getters['learningPaths/getLearningPaths'].map(x => x.id)
      this.$emit('lps-selected', this.selected_lps_obj)
    },
    UnselectAllLP(){
      this.$emit('lps-selected', [])
      this.selected_lps_obj = []
    },
    filterLearningPaths(source){
      this.lp_source = source
      this.searchQuery = ''
      let filtered = []
      if(source != 'smileandlearn'){
        filtered = this.$store.getters['learningPaths/getLearningPaths'].filter(l => l.source.includes(source))

      }

      this.showLearningPathDetails = false
      this.showLearningPathAllocationView = false
      this.learningPathViewData = {}
      this.learningPathAllocationData = {}
      this.hierarchy_selected = ''
      // console.log("filterLearningPaths", filtered)
      this.learning_paths = filtered
    },
    toggleStar(lp){
      // console.log("star", lp.id)
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/app-learning-paths.scss";
 #sidebar-right{
  width: 50vw;
 }
.no-bg{
  background: none;
}

.floating-bottom-assign-summary-button {
  position: fixed;
  bottom: 2%;
  right: 80px;
  z-index: 1031;
  .btn {
    box-shadow: 0 0px 4px 0px #00000041 !important;
    &:hover {
      box-shadow: none !important;
    }
  }
}

</style>