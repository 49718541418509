<template>
  <div>
    <div class="summary-header">
      <div class="bloque-inline">
        <h3 style="margin-top: 0.5rem;">
          {{ $t('learning_path.summary') }}
        </h3>
        <b-button 
          @click="switchView = !switchView"
          variant="flat-primary"
          class="btn-icon rounded-circle"
          v-b-tooltip.hover.up="switchView ? $t('learning_path.see_by_dreamers') : $t('learning_path.see_by_paths')"
        >
          <svg v-if="!switchView" width="20px" height="20px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path fill="#87bced" d="M507.31 84.69L464 41.37c-6-6-14.14-9.37-22.63-9.37H288V16c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v16H56c-13.25 0-24 10.75-24 24v80c0 13.25 10.75 24 24 24h385.37c8.49 0 16.62-3.37 22.63-9.37l43.31-43.31c6.25-6.26 6.25-16.38 0-22.63zM224 496c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V384h-64v112zm232-272H288v-32h-64v32H70.63c-8.49 0-16.62 3.37-22.63 9.37L4.69 276.69c-6.25 6.25-6.25 16.38 0 22.63L48 342.63c6 6 14.14 9.37 22.63 9.37H456c13.25 0 24-10.75 24-24v-80c0-13.25-10.75-24-24-24z"/></svg>
          <svg v-else width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path fill="#87bced" d="M120 72c0-39.765 32.235-72 72-72s72 32.235 72 72c0 39.764-32.235 72-72 72s-72-32.236-72-72zm254.627 1.373c-12.496-12.497-32.758-12.497-45.254 0L242.745 160H141.254L54.627 73.373c-12.496-12.497-32.758-12.497-45.254 0-12.497 12.497-12.497 32.758 0 45.255L104 213.254V480c0 17.673 14.327 32 32 32h16c17.673 0 32-14.327 32-32V368h16v112c0 17.673 14.327 32 32 32h16c17.673 0 32-14.327 32-32V213.254l94.627-94.627c12.497-12.497 12.497-32.757 0-45.254z"/></svg>

        </b-button>
      </div>                
    </div>
<!-- {{switchView ? allocation : trasposeAllocation}} -->

    <app-collapse v-if="switchView">
          <app-collapse-item title="" v-for="lp in Object.keys(allocation)" :key="lp">
            <template v-slot:header >
                <span class="d-flex justify-start align-center">
                  <b-img
                    :src="require('@/assets/images/learning_paths/'+resolveLP(lp).img+'.png')"
                    blank-color="#ccc"
                    height="30"
                    alt="placeholder"
                    class="mr-1"
                  />
                  <span style="font-weight: 500">
                    {{resolveLP(lp).name}}
                  </span>
                </span>
              </template>
            <b-list-group v-if="Object.keys(allocation[lp]).length > 0" >
                <b-list-group-item 
                  v-for="dreamer in Object.keys(allocation[lp])" :key="dreamer"
                  >
                  <span class="d-flex justify-content-between align-center">
                    <span class="d-flex justify-start align-center">
                  
                    <b-img
                        :src="require('@/assets/images/avatar/AvatarSprite_'+resolveDreamer(dreamer).avatar+'.png')"
                        blank-color="#ccc"
                        height="30"
                        alt="placeholder"
                        class="mr-1 p-25"
                      />
                      <div>
                      {{resolveDreamer(dreamer).name}}
                      <br>
                      <span v-if="allocation[lp][dreamer].start_date || allocation[lp][dreamer].end_date" style="font-size:11px" class="text-muted">
                        {{allocation[lp][dreamer].start_date ?  new Date(allocation[lp][dreamer].start_date).toLocaleDateString(geti18n):null }}
                          <span v-if="allocation[lp][dreamer].start_date && allocation[lp][dreamer].end_date"> - </span>
                        {{allocation[lp][dreamer].end_date ?  new Date(allocation[lp][dreamer].end_date).toLocaleDateString(geti18n):null }}
                      </span>
                      </div>
                    </span>
                    <b-button 
                      @click="allocateDreamer([dreamer, lp])"
                      variant="flat-danger"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                    
                  </span>
                  </b-list-group-item>
              </b-list-group>
              <p v-else class="text-center">{{$t('table.no_dreamers')}}</p>
          </app-collapse-item>
    </app-collapse>
    <app-collapse v-else>
          <app-collapse-item title="" v-for="dreamer in Object.keys(trasposeAllocation)" :key="dreamer">
            <template v-slot:header >
                <span class="d-flex justify-start align-center">
                  <b-img
                    :src="require('@/assets/images/avatar/AvatarSprite_'+resolveDreamer(dreamer).avatar+'.png')"
                    blank-color="#ccc"
                    height="30"
                    alt="placeholder"
                    class="mr-1"
                  />
                  <span style="font-weight: 500">
                    {{resolveDreamer(dreamer).name}}
                  </span>
                </span>
              </template>
            <b-list-group >
                <b-list-group-item 
                  v-for="lp in trasposeAllocation[dreamer]" :key="lp"
                  >
                  <span class="d-flex justify-content-between align-center">

                    <span class="d-flex justify-start align-center">
                    <b-img
                        :src="require('@/assets/images/learning_paths/'+resolveLP(lp).img+'.png')"
                        blank-color="#ccc"
                        height="30"
                        alt="placeholder"
                        class="mr-1 p-25"
                      />
                      <div>
                      {{resolveLP(lp).name}}
                      <br>
                      <span v-if="allocation[lp][dreamer].start_date || allocation[lp][dreamer].end_date" style="font-size:11px" class="text-muted">
                        {{allocation[lp][dreamer].start_date ?  new Date(allocation[lp][dreamer].start_date).toLocaleDateString(geti18n):null }}
                          <span v-if="allocation[lp][dreamer].start_date && allocation[lp][dreamer].end_date"> - </span>
                        {{allocation[lp][dreamer].end_date ?  new Date(allocation[lp][dreamer].end_date).toLocaleDateString(geti18n):null }}
                      </span>
                      </div>
                    </span> 
                    <b-button 
                      @click="allocateDreamer([dreamer, lp])"
                      variant="flat-danger"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                    
                  </span>
                  </b-list-group-item>
              </b-list-group>
          </app-collapse-item>
    </app-collapse>

    <b-row>
      <b-col>
        <div class="mt-3 text-center">
          <b-button @click="unselectAllLPs" variant="flat-danger">{{ $t('learning_path.delete_all_allocation')}}</b-button>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { BRow, BCol, VBTooltip, BMedia, BButton, BImg, BCardText, BListGroup, BListGroupItem } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BMedia, BButton,
    BImg,
    BCardText,
    BListGroup, BListGroupItem,
    BRow, BCol, 
    AppCollapse,
    AppCollapseItem,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return {
      switchView: false,
      //allocation:  {},
    }
  },
  mounted(){
    //this.allocation = this.$store.getters['learningPaths/getAllocation']//[lp.id]
  },
  computed: {
    geti18n(){
      return this.$store.getters['user/geti18n']
    },
    allocation(){
      return this.$store.getters['learningPaths/getAllocation']
    },
    trasposeAllocation(){
        // 1. Iteramos por los pares {lp_id : [dreamers_ids]}
        // 2. Le aplanamos para que sea [[lp_id, dreamer_id], ...]
        // 3. Con reduce, agrupamos por dreamer_id.

        let traspAlloc = Object.entries(this.$store.getters['learningPaths/getAllocation']).map(lp => (
            Object.entries(lp[1]).map( 
                (alloc => [lp[0], alloc[0], alloc[1].order])
            )
        )).flat().reduce((obj, lp) => {
            obj[lp[1]] = [...obj[lp[1]] || [], [lp[0], lp[2]]]; 
            return obj;
        }, {})
        
        Object.keys(traspAlloc).forEach(function(k, ind) {
            traspAlloc[k] = Object.values(traspAlloc[k].sort((a, b) => a[1] - b[1])).map(a => a[0]);
        });

        return traspAlloc
    },
  },
  methods:{
    resolveLP(lp){
        return this.$store.getters['learningPaths/getLearningPaths'].find(l => l.id == lp);
    },
    resolveDreamer(dreamer){
      return this.$store.getters['learningPaths/getDreamers'][dreamer]
    },
    allocateDreamer([dreamer, lp]){
      // console.log(dreamer, lp)
      this.$store.commit('learningPaths/allocateDreamer', [[parseInt(dreamer)], parseInt(lp), 'one'])

    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    unselectAllLPs(){
      this.$bvModal
        .msgBoxConfirm(this.$t('learning_path.confirm_unselect_all'), {
          title: this.$t('learning_path.delete_all_allocation'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.$store.commit('learningPaths/unselectAllAllocation')
            this.makeToast('warning', this.$t('Warning'), this.$t('learning_path.confirm_unselect_all_success_text'));
          }
        })
    },
  }
}
</script>

<style scoped>
.bloque-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.summary-header{
    height: 70px;
    color: #3a8cb5;
    padding: 10px;
}

</style>