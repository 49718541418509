<template>
  <div>
    <b-row v-if="loading">
      <b-col cols="12"  >
        <div class="text-center">
          <b-spinner label="Loading..." />
        </div>
      </b-col>
    </b-row>
    <template v-else>
      <b-row>
        <b-col cols="3" class="d-lg-block d-none">
            <left-filter-sidebar 
              @contents-hierarchy-selected="selectedContentsHierarchy"
              @search="searchContents"
              @template-changed="handleTemplateChange"
              :filterOptions="filterOptions"
              :contentsHierarchy="contentsHierarchy"/>
          </b-col>
        <b-col class="ecommerce-application">
          <contents-selector-container 
              :loading="loading_contents"
              :searchword.sync="searchword"
              :filterOptions="filterOptions"
              :contentsHierarchy="contentsHierarchy"
              :contents="contents"
              :report_lang="report_lang"
              :route_contents="selected_contents"
              :isReport="true"
              :showSearchbar.sync="showSearchbar"
              @select-all-content="selectAllContent"
              @toggle-content-in-list="toggleContentInList"
              @unselect-filter="unselectFilter"
              @search="searchContents"
              @contents-hierarchy-selected="selectedContentsHierarchy"
              />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BSpinner } from 'bootstrap-vue'
import { getContentsForReports, getContentsHierarchyForReports} from '@/api/reports'
import LeftFilterSidebar from '@/views/learning-paths/components/LeftFilterSidebar.vue'
import ContentsSelectorContainer from '@/views/learning-paths/components/ContentsSelectorContainer.vue'

export default {
  components:{
    BRow, BCol, BSpinner,
    LeftFilterSidebar,
    ContentsSelectorContainer
  },
  props:{
    report_lang:{
      type: String,
      required: true
    },
    ids:{
      type: Array,
      required: true
    },
    isGroup:{
      type: Boolean,
      required: true
    },
    selected_contents:{
      type: Array,
      required: true,
      default() {
          return []
      }
    },
    endDate:{
      type: String,
      required: true
    },
    startDate:{
      type: String,
      required: true
    }
  },
  data(){
    return {
      loading: true,
      loading_contents:false,
      all_contents: [],
      contents: [],
      filterOptions:[],
      contentsHierarchy:[],
      searchword:'',
      showSearchbar: true,
    }
  },
  mounted(){
    this.getData()
  }, 
  methods: {
    selectedContentsHierarchy(contents){
      if(contents.length == 0){
        this.contents = this.all_contents
      } else {
        let filtered_contents = []
        contents.forEach(id => {
          filtered_contents.push(this.all_contents[id])
        });
        this.contents = filtered_contents
      }
      // console.log(contents, "selectedContentsHierarchy - ContentsSelection")
    },
    unselectFilter(filters){
      this.filterOptions.map(f => {
        if(filters[0]==f.name){
          f.options.map(op => {
            if(filters[1] == op.id){
              op.selected = !op.selected
            }
          })
        }
      })
    },
    toggleContentInList(content){
      // console.log(content)
      let route_contents = [...this.selected_contents]

      const index = route_contents.findIndex(object => {
        return object.id === content.id;
      });

      if(index < 0){
        route_contents.push(content)
      } else {
        route_contents.splice(index, 1)
      }
      this.$emit('contents-selected', route_contents)

    },
    selectAllContent(contents){
      // console.log("all contents", contents)
      this.$emit('contents-selected', Object.values(contents))

    },
    async searchContents(){
      //// console.log(this.searchword, this.filterOptions)
      if(this.loading_contents==false){
        this.loading_contents = true
        let data = {
          'q': this.searchword,
          "user_id": this.$store.getters['user/getUserData'].id,
          'token': this.$store.getters['user/getUserData'].sso,
          "lang": this.report_lang,
          
          "ids": this.ids.map(x => x.id),
          "is_group": this.isGroup,
  
          'start_date':  new Date(this.startDate).getTime(),
          'end_date': new Date(this.endDate).getTime(),

        }
        this.filterOptions.map(cat => {
            let thisCatSelections = []
            if(cat.name=='age'){
                let ages = []
                cat.options.map(op => {
                    if(op.selected){
                        let ages_arr = op.id.split('-')
                        ages_arr.map(age => {
                            ages.push(parseInt(age))
                        })
                    }
                })
                if(ages.length == 0){
                  thisCatSelections = []
                } else {
                  let min = Math.min(...ages)
                  let max = Math.max(...ages)
                  
                  thisCatSelections = [min, max]
                }
            } else {
                cat.options.map( op =>{
                    if(op.selected){
                        thisCatSelections.push(op.id)
                    }
                })
            }
            data[cat.name] = thisCatSelections
        })
        // console.log("search", data)
        await this.$http.post(getContentsForReports, data).then( response =>{
          if(response.status == 200){

              //this.all_contents = response.data.contents
              this.contents = response.data.contents //obj de obj
              this.loading_contents = false
            } else {
              this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.msg_code));
            }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      }

    },
    async getData(){
      
      let data = {
          'user_id':  this.$store.getters['user/getUserData'].id,
          'token': this.$store.getters['user/getUserData'].sso,
          "lang": this.report_lang,
          "ids": this.ids.map(x => x.id),
          "is_group": this.isGroup,
          'start_date':  new Date(this.startDate).getTime(),
          'end_date': new Date(this.endDate).getTime(),

          "q": "",
          "academic_areas": [],
          "cognitive_skills": [],
          "competences": [],
          "intelligence": [],
          "language_learning": [],
          "content_types": [],
          "age": [], 
      }

      await this.$http.post(getContentsHierarchyForReports, data).then( response =>{
        // console.log(response.data, "--getContentsHierarchyForReports-----------------")
        if(response.status == 200){
          this.contentsHierarchy = response.data.hierarchy
          this.filterOptions = response.data.filterOptions
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.msg_code));
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })

      await this.$http.post(getContentsForReports, data).then( response =>{
        if(response.status == 200){
          this.all_contents = response.data.contents
          this.contents = response.data.contents //obj de obj
          this.loading = false
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.msg_code));
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    handleTemplateChange(){
      this.showSearchbar = !this.showSearchbar
    },
  }
}
</script>

<style>

</style>