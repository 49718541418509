<template>
  <div class="scroll" @mouseenter="disableBodyScroll" @mouseleave="enableBodyScroll">
    <div class="sidebar">
      <div class="sidebar-shop show" style="width:100%">

        <!-- Filters' Card -->
        <b-row class="m-0 my-25">
            <b-button-group size="sm" style="width:100%">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="show == 'filters'?'secondary':'outline-secondary'"
                @click=showFilters
              >
                <feather-icon
                  icon="FilterIcon"
                  size="12"
                />  {{$t('learning_path.filters')}}
              </b-button>
              <b-button
                v-if="contentsHierarchy.length > 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click=showFilters
                :variant="show == 'hierarchy'?'secondary':'outline-secondary'"
              >
                <feather-icon
                  icon="ListIcon"
                  size="12"
                />  {{$t('Categories')}}
              </b-button>
              
            </b-button-group>
          
        </b-row>
        <template v-if="show == 'filters'">
          <b-card no-body class="my-1">
            <app-collapse>
              <app-collapse-item
                v-for="filter in filterOptions"
                :key="filter.name"
                :title="$t('learning_path.'+filter.name)">
                <b-list-group>
                  <b-list-group-item button
                    class="d-flex "
                    v-for="option in filter.options"
                    :key="option.id"
                    @click="option.selected = !option.selected"
                  >
                    <!-- <b-form-checkbox
                      v-model="option.selected"
                    /> -->
                    <feather-icon
                      :icon="option.selected?'CheckSquareIcon':'SquareIcon'"
                      size="16"
                      class="mt-25 mr-1"
                    />
                    <span>{{filter.name == 'language_learning'? $t('lang.'+option.name): filter.name == 'content_types'? $t('content_types.'+option.name) : (filter.name == 'intelligence' && option.id=='gamification')?$t('intelligence.gamification'):option.name}}</span>
                  </b-list-group-item>
                </b-list-group>
              </app-collapse-item>
            </app-collapse>
          </b-card>

          <b-row class="m-0 mb-2">
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                :disabled="loading"
                variant="secondary"
                @click="handleClickAndScroll"
              >
                {{$t('learning_path.search')}}
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <b-card no-body class="my-1">
            <contents-hierarchy 
              :contentsHierarchy="contentsHierarchy" 
              @contents-hierarchy-selected="selectedContentsHierarchy"
            />
          </b-card>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BRow, BCol, BCard, BFormRadioGroup, BListGroup, BListGroupItem, 
  BFormCheckbox, BButtonGroup, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ContentsHierarchy from './ContentsHierarchy.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormRadioGroup,
    AppCollapse,
    AppCollapseItem,
    BListGroup, 
    BListGroupItem,
    BFormCheckbox,
    BButtonGroup, BButton,


    ContentsHierarchy
  },
  directives: {
    Ripple,
  },
  props:{
    loading:{
      type: Boolean,
      required: false,
      default: false,
    },
    filterOptions:{
      type: Array,
      required: true
    },
    contentsHierarchy:{
      type: Array,
      required: false,
      default: () => []
    },
  },
  data(){
    return{
      show:'filters',
      showSearchbar: true,
    }
  },
  mounted(){
  },
  methods:{
    handleClickAndScroll() {
    this.$emit('search');
    window.scrollTo(0, 0);
  },
    selectedContentsHierarchy(contents){
      this.$emit('contents-hierarchy-selected', contents)
    },
    disableBodyScroll() {
      document.body.style.overflow = 'hidden';
    },
    enableBodyScroll() {
      document.body.style.overflow = '';
    },
    showFilters(){
      this.show = this.show == 'filters' ? 'hierarchy' : 'filters'
      this.$emit('template-changed')
    },
  }
}
</script>

<style lang="scss" scoped>
.scroll {
    margin:4px, 4px;
    width: 100%;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>