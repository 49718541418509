<template>
  <div class="sidebar-left" id="panelRutas">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu" :style="{
              width: '100%',
              maxHeight: vh,
              overflowX: 'hidden',
              overflowY: 'auto'
          }">
          <!-- <div v-if="isAllocation" class="text-center pt-1">
            <b-button @click="unselectAllLPs" variant="flat-danger">{{ $t('learning_path.delete_all_allocation')}}</b-button>
          </div> -->
          <b-list-group class="pt-1 list-group-messages">
            <template v-for="filter in $store.getters['learningPaths/getHierarchy']">
              <b-list-group-item
                v-if="filter.source == 'all'"
                :key="filter.source"
                class="pointer"
              >
                <b-img 
                  width="22px"
                  class="mr-75"
                  :src="require('@/assets/images/learning_paths/foca256.png')" fluid :alt="$t('learning_path.all')" />
                <span class="align-text-bottom line-height-1">{{ $t('learning_path.all') }}</span>
              </b-list-group-item>
             
             <!-- v-if="resolveRoutesLength(filter.source) > 0" -->
              <template v-else>
                <b-list-group-item
                  v-if="resolveRoutesLength(filter.source)"
                  :key="filter.source"
                  :active="filter.source == active_filter"
                  @click="selectFilter(filter)"
                  class="pointer"
                >
                  <feather-icon
                    v-if="filter.source != 'smileandlearn' "
                    :icon="resolveHierarchyIcon(filter.source)"
                    size="18"
                    class="mr-75"
                  />
                  <b-img 
                    v-else
                    width="22px"
                    class="mr-75"
                    :src="require('@/assets/images/learning_paths/foca256.png')" fluid :alt="$t('learning_path.'+filter.source)" />
                    <span class="align-text-bottom line-height-1">{{ $t('learning_path.'+filter.source) }}</span>
                  <b-badge
                    v-if="filter.source != 'smileandlearn' && filter.source != 'all'"
                    pill
                    :variant="resolveSourceVariant(filter.source)"
                    class="float-right"
                  >
                    {{ resolveRoutesLength(filter.source) }}
                  </b-badge>
                </b-list-group-item>
              </template>
            </template>
          </b-list-group>
          <div > <!-- :style="{
              margin:'4px 4px',
              width: '100%',
              maxHeight: calcMaxHeight, 
              overflowX: 'hidden',
              overflowY: 'auto'
          }" --> 
            <template v-if="active_filter == 'smileandlearn'">
              <h6 class="section-label mt-2 mb-1 px-2">
                {{$t('learning_path.sections')}}
              </h6>
              <div class="p-1">
                <TreeNode 
                  v-for="node in $store.getters['learningPaths/getSmileHierarchy']" 
                  :key="node.id" 
                  :node="node" 
                  @selected-node="selectedNode"
                />
              </div>

            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  BImg, BButton, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TreeNode from './TreeNode.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    TreeNode, 
    // BSV
    BImg, BButton,
    BListGroup,
    BListGroupItem,
    BBadge,
  },
  props: {
    isAllocation:{
      type: Boolean,
      required:false,
      default: false
    },
    isReport:{
      type: Boolean,
      required:false,
      default: false
    },
    isCatalog:{
      type: Boolean,
      required:false,
      default: false
    },
    learning_paths: {
      type: Array,
      required: true
    }
  },
  data(){
    return {
      vh:512,
      active_filter: this.isReport && this.$store.getters['user/getUserData'].id == 1081 
        ? 'all'
        : this.$store.getters['user/getUserData'].id == 1081 || this.isCatalog
          ? 'smileandlearn' : 
            this.isReport ? 'all' : 'own',
    }
  },
  mounted(){
    this.vh = document.getElementById("panelRutas").clientHeight
    window.addEventListener('resize', this.handleWindowSizeChange);
  },
  computed:{
    calcMaxHeight(){
      let seccion = 0
      let filters = this.$store.getters['learningPaths/getHierarchy']
      filters.forEach(filter => {
        if (this.resolveRoutesLength(filter.source) > 0) {
          seccion += 1
        }
      });

      let sum = this.vh -14 -(37 * seccion)
      if(this.isAllocation){
        sum = sum -52  //espacio del boton delete all allocations
      }
      return sum+'px';
    },
  },
  methods:{
    handleWindowSizeChange(){
      this.vh = document.getElementById("panelRutas").clientHeight
    },

    /* unselectAllLPs Ya no se usa? */
    unselectAllLPs(){
      this.$bvModal
        .msgBoxConfirm(this.$t('learning_path.confirm_unselect_all'), {
          title: this.$t('learning_path.delete_all_allocation'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.$store.commit('learningPaths/unselectAllAllocation')
            this.makeToast('warning', this.$t('Warning'), this.$t('learning_path.confirm_unselect_all_success_text'));
          }
        })
    },
    resolveRoutesLength(source){
      let filtered_lps = this.$store.getters['learningPaths/getLearningPaths'].filter(lp => lp.source.includes(source))
      return filtered_lps.length
    },
    resolveHierarchyIcon(source){
      switch (source) {
        case 'own':
          return 'SmileIcon'
          break;
        case 'recommended':
          return 'StarIcon'
          break;
        case 'school':
          return 'HomeIcon'
          break;  
        case 'shared':
          return 'Share2Icon'
          break;      
        default:
          return 'ArchiveIcon'
          break;
      }
    },
    selectedNode(node){
      this.$emit('selected-node', node.id)
      this.$emit('close-left-sidebar')
    },
    selectFilter(filter){
      // console.log(filter.source)
      if(filter.source != 'smileandlearn'){
        this.$emit('close-left-sidebar')
      }
      this.active_filter = filter.source
      this.$emit('filter-lps', filter.source)
    },
    changeTemplate(template){
      this.$emit('template-changed', template)
    },
    makeToast(variant = null, tit ='',msg = '') {
        this.$bvToast.toast(msg, {
          title: tit,
          variant,
          solid: false,
          noAutoHide: true
        })
    },
  },
}
</script>

<style lang="scss" scoped>

.scroll {
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
